import { SET_MESSAGE, CLEAR_VERTIFYCHECK, VERTIFYCHECK } from "../actions/types";

const initialState = {
	token: false,

};

export default function TokenReducer(state = initialState, { type, payload }) {
	switch (type) {
		case VERTIFYCHECK:
			return {
				token:payload.token
			};

		case CLEAR_VERTIFYCHECK:
			return {
				token:payload.token=false
			};
		default:
			return state;
	}


} 

/*
export default function message (state = initialState, { type, payload }) {
  switch (type) {
    case SET_MESSAGE:
      return {
				//...state,
				type: payload.message,
  			};


    case CLEAR_MESSAGE:
      return   payload.message="";
    default:
      return state;
  }
}



*/
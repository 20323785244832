import axios from "axios";

 
class AuthService {
  login(username, enigma) {
    return axios
      .post("https://mydemo.madilink.net/api/auth/signin", {
        username,
        enigma
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
        
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, enigma,tcid) {
    return axios.post("https://mydemo.madilink.net/api/auth/signup", {
      username,
      enigma,
      tcid
    });
  }

  userRegister(username,enigma,tcid) {
    return axios.post("https://mydemo.madilink.net/Airjin/userekle", 
    {
      username,
      enigma,
      tcid,
      roles:["user"]
    });
  }


  googleauthenticator(token) {
    return axios.post('https://mydemo.madilink.net/Airjin/verify',{token});
    
  }
  ZincirRegister(username,enigma,tcid) {
    return axios.post("https://mydemo.madilink.net/Airjin/userekle", 
    {
      username,
      enigma,
      tcid,
      roles:["zincir"]
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  

  
}

export default new AuthService();

 
import React, { useRef } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
 import PerfectScrollbar from "perfect-scrollbar";

 import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";

import routes from "../routes";
import PropTypes from 'prop-types';

import logo from "../assets/img/react-logo.png";
import { BackgroundColorContext } from "../contexts/BackgroundColorContext";
import DashboardControl from '../views/DashboardControl';

var ps;
const propTypes = {
  messages: PropTypes.array.isRequired
};

function Admin  (props) {
  
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  
  
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
   
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
   
  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <React.Fragment>
 
          <div>
            
            <Sidebar  
              routes={routes} 
            />
            <div className="main-panel" ref={mainPanelRef} data={color}>
{/*              <DashboardControl messages={this.props.message}/>
 */}         
               <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/admin/dashboard" />
              </Switch>
              
            </div>
          </div>
       </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;

export const styles = {
    appBar: {
       // backgroundColor: "#343A40",
        height: "50px",
        '& .MuiToolbar-regular': {
            minHeight: "50px"
        }
    },
    name: {
        marginRight: "15px"
    },
    input: {
        //color: "#2EFF22"
        color: "White"

      },
    link: {
        textTransform: "unset",
        color: "#a5a5a5",
        margin: "0 20px",
        textDecoration: "unset"
    },
    form: {
        //marginLeft: "10px"
        color: "#a5a5a5"

    },
    textField: {
        margin: "15px 0",
        color: "white",
        backgroundColor:"#a5a5a5",
        fontWeight:"bold",
        textAlign: 'center',
        
 
    }, 
    search: {
        //marginTop: "100px",
        border: "1px solid white",
        color: "#white",        
        backgroundColor:"#a5a5a5",
    },
    detail: {
        margin: "5px 0"
    },
    edit: {
        backgroundColor: "orange",
        border: "4px solid orange",
        borderRadius: "5px",
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "bold",
        color: "white",
        opacity: 0.8,
        

    },
    publish: {
        backgroundColor: "#0062cc",
        border: "4px solid #0062cc",
        borderRadius: "5px",
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "bold",
        color: "white",
        opacity: 0.8
    },
    delete: {
        backgroundColor: "red",
        border: "4px solid red",
        borderRadius: "5px",
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "bold",
        color: "white",
        opacity: 0.8

    },
    update: {
        backgroundColor: "green",
        border: "4px solid green",
        borderRadius: "5px",
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "bold",
        color: "white",
        opacity: 0.8

    },
    buttonWrapper: {
        marginTop: "20px"
    },
    button: {
        marginRight: "15px",
        color: "white",
        fontSize: "13px",
        textTransform: "none",
        height: "25px"
    },
    removeAll: {
        marginTop: "20px"
    },
    tutorial: {
        marginLeft: "25px"
    }
};
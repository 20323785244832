import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Dashboard from "../views/Dashboard";
//import "../components/tasarim.css";
import { connect } from 'react-redux';
import { Redirect } from "react-router";
import { Switch, Route, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import message from '../actions/message'
import { withRouter } from "react-router";
import { createStore } from 'redux';
import { createBrowserHistory } from 'history';

import { googleauthenticator } from "../actions/vertifyAction"



import AuthService from "../../src/services/auth.service";
const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const user = AuthService.getCurrentUser();



class vertify extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    vertify:  PropTypes.array.isRequired

  };
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeOnay = this.onChangeOnay.bind(this);
    const test = false;

    this.state = {
      username: "",
      enigma: "",
      loading: false,
       token: null,
      hide: 0,
      redirect: false,
      errors: {},


    };
  }

  componentDidMount() {
  }


  onChangeOnay(e) {
    this.setState({
      token: e.target.value
    });

  }

  componentDidMount() {
    //console.log(this.props.vertifyReducer)
   }

  handleLogin(e) {
    e.preventDefault();

    /*  this.setState({
       message: "",
       loading: true
     }); */
    const list = []


    this.form.validateAll();



    if (this.checkBtn.context._errors.length === 0) {
     this.props.googleauthenticator(this.state.token);
     this.setState({ redirect: true	});
  

    }

  }


  render() {
    const { errors } = this.state;

    console.log(this.props);

    const form = (<div className="col-md-12" >
      <div className="card card-container">

        <Form
          onSubmit={this.handleLogin}
          ref={c => {
            this.form = c;
          }}
        >
          <div className="form-group">
            <label htmlFor="token">Onay</label>
            <Input
              type="text"
              className="form-control"
              name="token"
              value={this.state.token}
              onChange={this.onChangeOnay}
              validations={[required]}
            />
          </div>


          <div className="form-group">
            <button
              className="btn btn-primary btn-block"
            //  disabled={this.state.loading}
            >

              <span>Authenticate</span>

            </button>


          </div>

          <CheckButton
            style={{ display: "none" }}
            ref={c => {
              this.checkBtn = c;
            }}
          />

        </Form>

      </div>



    </div>
    );
///admin/dashboard
    return (
      <div>
        {
          this.props.vertifyReducer.token?this.props.history.push("/admin/dashboard") : form
        }
      </div>

    );
  }
}
const mapDispatchtoProps = 
{
  googleauthenticator,
}

const mapStateToProps = ({ message,vertifyReducer }) => {
  return {
    message,vertifyReducer
  }
};
export default connect(mapStateToProps, mapDispatchtoProps)(vertify);

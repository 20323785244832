import axios from 'axios';
import http from './http-common'
import authHeader from './auth-header';


class UserService {
  getPublicContent() {
    return axios.get('https://mydemo.madilink.net/api/test/all');
  }

  getUserBoard() {
    return axios.get('https://mydemo.madilink.net/api/test/user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get('https://mydemo.madilink.net/api/test/zincir', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get('https://mydemo.madilink.net/api/test/admin', { headers: authHeader() });
  }
  getAlluser() {
    return axios.get("https://mydemo.madilink.net/Airjin/kullaniciTumlisteUser");
  }
  getAllzincir() {
    return axios.get("https://mydemo.madilink.net/Airjin/kullaniciTumlisteZincir");
  }

  get(customerId) {
    return axios.get(`https://mydemo.madilink.net/Airjin/Kullanicibul/${customerId}`);
  }

  update(id,data) {
    return axios.put(`https://mydemo.madilink.net/Airjin/Kullaniciguncelle/${id}`,data);
  }   

  RoleUpdate(id,datas) {
    return axios.put(`https://mydemo.madilink.net/Airjin/RoleDegistir/${id}`,datas);
  } 
  delete(customerId) {
    return axios.delete(`https://mydemo.madilink.net/Airjin/Kullanicisil/${customerId}`);
  }

  findByTitle(title) {
    return axios.get(`https://mydemo.madilink.net/Airjin/customers?username=${title}`);
  }
 

 
  userRegister(username,enigma,tcid) {
    return axios.post("https://mydemo.madilink.net/Airjin/userekle", 
    {
      username,
      enigma,
      tcid,
      roles:["user"]
    });
  }
  ZincirRegister(username,enigma,tcid) {
    return axios.post("https://mydemo.madilink.net/Airjin/userekle", 
    {
      username,
      enigma,
      tcid,
      roles:["zincir"]
    });
  }

 
 
  
 




}

export default new UserService();

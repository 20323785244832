import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/types";


const initialState = {
 	done: false,
	error: {},
	 
};
 
export default function message (state = initialState, { type, payload }) {
  switch (type) {
    case SET_MESSAGE:
      return {
				//...state,
				type: payload.message,
  			};


    case CLEAR_MESSAGE:
      return   payload.message="";
    default:
      return state;
  }
}


import React, { Component} from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import AuthService from "../services/auth.service";
import axios from "axios";
import { format } from "date-fns";
import { DatePicker } from '@y0c/react-datepicker';
import { Line, Bar } from "react-chartjs-2";
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Button,
  Col,
  UncontrolledTooltip
} from "reactstrap";
const currentUser = AuthService.getCurrentUser();

export default class BoardModerator extends Component {
  constructor(props) {
    super(props);
    this.getUserInfo = this.getUserInfo.bind(this);

    this.state = {
      content: "",
      redirect: null,
      userReady: false,
      tcid: false,
      name: "",
      currentUser: { username: "" },
      persons: [],
      birimfiyat: false,
      startDate: new Date(),
      finishDate: new Date(),
      testcenter_id: null,
      companyNames: [],
      data: "",
      totalvalues: [],
      bigChartData: "data1",
      sum_costs: [],
      labels: []

    };
  }; 



  onChange = (date) => {
    var date = new Date(date);
    var formattedDate = format(date, "yyyyMMdd");
    //console.log(date.toDate());
    console.log(formattedDate);
    this.setState({ startDate: formattedDate })
 
  }

  onChangeiki = (dateiki) => {
    var date = new Date(dateiki);
    var formattedDate = format(date, "yyyyMMdd");
    console.log(formattedDate);
    this.setState({ finishDate: formattedDate })

 

  }

  componentDidMount() {
    UserService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

  }
  //birden fazla veri varsa map kullanilir
  /*
     <ul>
              {this.state.persons.map(person =><li>
                  <br />
                  <div>
                    <h3 
                    style={{ backgroundColor: "lightblue", fontSize: 24, textAlign: "center" }}
                    >
                      Testcenter id : <strong>{person.name}</strong> <br />
                      </h3>
  
                  </div>{/* 
                  <div 
                  style={{ background: "white", paddingTop: 12, paddingBottom: 12 }}
                  >
                    <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
                    >
                      <strong> Testcenter Name : {person.name} </strong> <br /></h3>
                    <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
                    ><strong> Positive: {person.count_positive}</strong> <br /></h3>
  
                    <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
                    > <strong> Negative : {person.count_negative}</strong> <br /></h3>
                    <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
                    >
                      <strong> Unknown  : {person.count_x}</strong> <br /></h3>
  
                      <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
                    >
                      <strong> Unknown  : {person.count_values}</strong> <br /></h3>
  
                      
                  </div>
  
   }
   </li>
  
   )}
  
  */

  /*
  tekli alanlar icin 
  
  axios.get(`https://mydemo.madilink.net/Airjin/Analizm/customersPNCount/1/20210601/20210630`)
   
    .then(res => {
      const persons = res.data;
      this.setState({ persons });
   
    })
  
  // {persons.count_positive} gibi
  
  
  */


  getUserInfo()
  {
    let client_name = [];
    let totalvalues = [];
    let sum_cost = [];
    let name = [];
    let testcenter_id = [];
    let place = [];
    let labels=[];

    //this.getUserINFO();

    //axios.get(`https://mydemo.madilink.net/Airjin/Analizm/customersPNCount/2/20211014/20211030`)
    axios.get(`https://mydemo.madilink.net/Airjin/Analizm/customersPNCount/${currentUser.tcid}/${this.state.startDate}/${this.state.finishDate}`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
        console.log(res);
        for (const dataobj of persons) {

          totalvalues.push(parseInt(dataobj.total));
          sum_cost.push(parseInt(dataobj.sum_cost));
          client_name.push((dataobj.client_name));
          name.push((dataobj.name));
          var date = new Date(dataobj.test_date);
          var formattedDate = format(date, "yyyy/MM/dd");
          labels.push(formattedDate);

        }
        this.setState({ client_name: client_name })
        this.setState({ totalvalues: totalvalues })
        this.setState({ sum_cost: sum_cost })
        this.setState({ name: name })
        this.setState({ testcenter_id: testcenter_id })
        this.setState({ place: place })
        this.setState({ labels: labels })

      })
      .catch(err => {
        console.log(err)
      }, []);

    /*     <td className="text-center">{person.name}</td>
                        <td className="text-center">{person.testcenter_id}</td>
                        <td className="text-center">{person.place}</td> */

  }










  componentDidUpdate() {


    

  }

  render() 
  
  {
    const { persons,labels,totalvalues,sum_cost,currentUser } = this.state

    const data1 = {
      labels:  labels,
      datasets: [
        {
          label: 'TOTAL VALUES',
          data:   totalvalues,
          fill: false, // for Line chart
          backgroundColor: '#FB8833',
          borderColor: '#FB8833' // for Line chart
        },
        {
          label: 'ÜCRET',
          data:   sum_cost,
          fill: false, // for Line chart
          backgroundColor: '#17A8F5',
          borderColor: '#17A8F5' // for Line chart
        }
      ]
    };
    const options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }


    return (
      <div className="container">

        <div
          style={{
            textAlign: "center",
            backgroundColor: "#23AEFF",
            paddingTop: 22,
            paddingLeft: 20, 
            paddingRight: 20,
            marginTop:10,
            //paddingBottom: 50,   
            display: "flex",
            justifyContent:"space-between",
            flexDirection: "row"

          }}
        >


          <h1>Başlangıç Tarihi  </h1>
          <DatePicker onChange={this.onChange} />


          <h1>Bitiş Tarihi  </h1>
          <DatePicker onChange={this.onChangeiki} />

          <Button onClick={this.getUserInfo}>Search</Button>

        </div>


        <div>
          {persons.map(person => <div
            style={{ background: "white", paddingTop: 50, paddingBottom: 12, marginTop: 1 }}
          >
            <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
            >
              <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
              >
                Testcenter id : <strong>{person.testcenter_id}</strong> <br />
              </h3>
              <strong> Testcenter Name : {person.name} </strong> <br /></h3>
            <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
            ><strong> Positive: {person.count_positive}</strong> <br /></h3>

            <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
            > <strong> Negative : {person.count_negative}</strong> <br /></h3>
            <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
            >
              <strong> Unknown  : {person.count_x}</strong> <br /></h3>

            <h3 style={{ color: "black", fontSize: 18, textAlign: "center" }}
            >
              <strong> Total Test  : {person.total}</strong> <br /></h3>


          </div>)}

          <div className="chart-area">
            <Line
              data={data1}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}
import React, { Component } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import AuthService from "../services/auth.service";
import axios from "axios";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { format } from "date-fns";
import { DatePicker } from '@y0c/react-datepicker';
import { RadioGroup, FormControl, FormLabel, FormControlLabel, Radio } from "@mui/material"

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const currentUser = AuthService.getCurrentUser();

export default class BoardUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            redirect: null,
            userReady: false,
            tcid: false,
            name: "",
            currentUser: { username: "" },
            persons: [],
            startDate: new Date(),
            finishDate: new Date()
        };
    };



    componentDidMount() {
        UserService.getUserBoard().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                    
                }
            }
        );

    }
    componentDidUpdate() {
        axios.get(`https://mydemo.madilink.net/Airjin/MusteriToplam`)



            .then(res => {
                const persons = res.data;
                this.setState({ persons });

            })

    }

    render() {
        const { persons, currentUser } = this.state;

        return (
            <div className="container">


                <div>
                    {this.state.persons.map(person =>
                        <div style={{ background: "white", textAlign: "center" }}
                        >


                            <h3 style={{ color: "black", fontSize: 25, textAlign: "center" }}  >
                                <div >
                                  {/*   <StyledBadge style={{marginTop:5}}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        variant="dot"
                                    >
                                        <Avatar style={{ margin: "center" }}
                                            // alt="Remy Sharp" 
                                            variant="square"
                                            src="https://mui.com/static/images/avatar/1.jpg"
                                            sx={{ width: 250, height: 200 }}
                                        />
                                    </StyledBadge> */}
                                </div>
                                Testcenter id :   <strong>{person.client_id}</strong> <br />
                                Test Center Adi :   <strong> {person.name} </strong> <br />
                                Toplam Merkez  : <strong>    {person.Toplam_Merkez}</strong>
                            </h3>


                        </div>)}
                </div>
            </div>
        );
    }
}
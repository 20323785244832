import React, { Component } from "react";
import TutorialDataService from "../services/user.service";
import { Link } from "react-router-dom";
import { styles } from "./css-common"
import axios from 'axios';
import Form from "react-validation/build/form";

import CheckButton from "react-validation/build/button";
import { TextField, Button, Grid, ListItem, withStyles, RadioGroup, FormControl, FormLabel, FormControlLabel, Radio } from "@material-ui/core";

import {
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";





class ZincirPaneli extends Component {
    constructor(props) {
        super(props);
        this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
        this.retrieveTutorials = this.retrieveTutorials.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.setActiveTutorial = this.setActiveTutorial.bind(this);
        this.searchTitle = this.searchTitle.bind(this);


        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangeEnigma = this.onChangeEnigma.bind(this);
        this.onChangeTcid = this.onChangeTcid.bind(this);
        this.onChangeRD = this.onChangeRD.bind(this);

        this.getTutorial = this.getTutorial.bind(this);
        this.updateTutorial = this.updateTutorial.bind(this);
        this.deleteTutorial = this.deleteTutorial.bind(this);


        this.state = {
            tutorials: [],
            id: null,
            username: null,
            value: null,
            enigma: null,
            tcid: null,
            roleId: null,
            currentTutorial: {
                id: null,
                username: "",
                enigma: "",
                token: "",
                tcid: "",
                yetki: false,
                arkaplanrengi: "",
                createdAt: "",
                updatedAt: "",
                ip_adress: "",
                roleId: "",


            },
            currentIndex: -1,
            searchTitle: "",
            username: []
        };
    }



    onChangeUsername(e) {
        const username = e.target.value;
        console.log("username" + JSON.stringify(this.state.username));
        this.setState(function (prevState) {
            return {
                currentTutorial: {
                    ...prevState.currentTutorial,
                    username: username
                }
            };

        });


    }

    onChangeEnigma = (e) => {
        const enigma = e.target.value;
        this.setState(function (prevState) {
            return {
                currentTutorial: {
                    ...prevState.currentTutorial,
                    enigma: enigma
                }
            };

        });
    }

    onChangeTcid = (e) => {
        const tcid = e.target.value;
        this.setState(function (prevState) {
            return {
                currentTutorial: {
                    ...prevState.currentTutorial,
                    tcid: tcid
                }
            };

        });
    }

    onChangeRD = (e) => {
        const roleId = e.target.value;
        this.setState(function (prevState) {
            return {
                currentTutorial: {
                    ...prevState.currentTutorial,
                    roleId: roleId
                }
            };
        });
        console.log("roleId" + roleId);

    }


    componentDidMount() {
        this.retrieveTutorials();
    }

    componentDidUpdate() {


        /* this.setState({ yetki: true });
        this.setState({ arkaplanrengi: "#a5a5a5" }) */
        // this.retrieveTutorials();
    }

    onChangeSearchTitle(e) {
        const searchTitle = e.target.value;

        this.setState({
            searchTitle: searchTitle
        });
        this.searchTitle();
    }

    retrieveTutorials() {
        TutorialDataService.getAllzincir()
            .then(response => {
                this.setState({
                    tutorials: response.data
                });
                //  console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    refreshList() {
        this.retrieveTutorials();
        this.setState({
            currentTutorial: null,
            currentIndex: -1
        });
    }

    setActiveTutorial(tutorial, index) {
        this.setState({
            currentTutorial: tutorial,
            currentIndex: index
        });

    }
    searchTitle() {
        TutorialDataService.findByTitle(this.state.searchTitle)
            .then(response => {
                this.setState({
                    tutorials: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }



    getTutorial(id) {
        //this.setState({ arkaplanrengi: "white" });
        //this.setState({ yetki: false });
        if (id != null) {
            TutorialDataService.get(id)
                .then(response => {
                    this.setState({ currentTutorial: response.data });
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        }
        else {
            console.log("Error");

        }
    }








    updateTutorial() {
        const id = this.state.currentTutorial.id;
        const username = this.state.currentTutorial.username;
        const enigma = this.state.currentTutorial.enigma;
        const tcid = this.state.currentTutorial.tcid;
        const roleId = this.state.currentTutorial.roleId;
        //  this.form.validateAll();


        const data = {
            username: username,
            enigma: enigma,
            tcid: tcid
        }


        TutorialDataService.update(id, data)
            .then(() => {
               
                this.setState({
                    message: "The User was updated successfully!"
                });
                this.refreshList();
            })
            .catch(e => {
                console.log(e);
            });

        const datas = {
            roleId: roleId
        }

        TutorialDataService.RoleUpdate(id, datas)
            .then(() => {
                 this.setState({
                    message: "The Role was updated successfully!"
                });
                this.refreshList();

            })
            .catch(e => {
                console.log(e);
            });
        this.retrieveTutorials();
    }

    deleteTutorial(id) {
        TutorialDataService.delete(id)
            .then(response => {
                this.refreshList();
                // console.log(response.data);
                // this.props.history.push('/admin/home')
            })
            .catch(e => {
                console.log(e);

            });
        this.retrieveTutorials();

    }

    render() {
        const { classes } = this.props
        const { searchTitle, tutorials, currentTutorial, currentIndex, yetki, arkaplanrengi } = this.state;

        return (
            <div className={classes.form} >
         
                <Row>
                <TextField  
                    value={searchTitle}
                    //className={classes.search}
                    label="Kullanıcı Ara"
                    variant="filled"
                    onChange={this.onChangeSearchTitle}
                />
              {/*   <Button
                    size="small"
                    variant="outlined"
                    className={classes.search}
                    onClick={this.searchTitle}>
                    Search
                </Button> */}

                    <Grid container>

                        <Grid item md={4}>
                            <h2>Zincir List </h2>


                            <div >
                                {tutorials &&
                                    tutorials.map((tutorial, index) => (
                                        <ListItem
                                            selected={index === currentIndex}
                                            onClick={() => this.setActiveTutorial(tutorial, index)}
                                            divider
                                            button
                                            key={index}>
                                            {tutorial.username}
                                        </ListItem>
                                    ))}
                            </div>

                        </Grid>
                        <Grid item md={8}>
                            {currentTutorial ? (
                                <div className={classes.tutorial}>
                                    {this.state.usernameiki}
                                    <div >
                                        <label>
                                            <strong>id :</strong>
                                        </label>

                                        {currentTutorial.id}
                                    </div>
                                    <div >
                                        <label>
                                            <strong>Username :</strong>
                                        </label>

                                        {currentTutorial.username}
                                    </div>
                                    <div >
                                        <label>
                                            <strong>Password:</strong>
                                        </label>
                                        {currentTutorial.enigma}
                                    </div>
                                    <div >
                                        <label>
                                            <strong>token:</strong>
                                        </label>
                                        {currentTutorial.token}
                                    </div>

                                    <div >
                                        <label>
                                            <strong>Test Merkezi ID:</strong>
                                        </label>
                                        {currentTutorial.tcid}
                                    </div>

                                    <div >
                                        <label>
                                            <strong>Oluşturulma Tarihi</strong>
                                        </label>{" "}
                                        {currentTutorial.createdAt}
                                    </div>

                                    <div >
                                        <label>
                                            <strong>Güncellenme Tarihi</strong>
                                        </label>{" "}
                                        {currentTutorial.updatedAt}
                                    </div>

                                    <div >
                                        <label>
                                            <strong>ip Adress</strong>
                                        </label>{" "}
                                        {currentTutorial.ip_adress}
                                    </div>
                                    <div >
                                        <label>
                                            <strong>Role ID</strong>
                                        </label>{" "}
                                        {currentTutorial.roleId}
                                    </div>


                                </div>
                            ) : (
                                <div>
                                    <br />
                                    <p className={classes.tutorial}>Bir Kullanici Seçin...</p>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <div style={{ margin: window.length }}>
                    </div>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Kullanıcı Detay</CardTitle>
                            </CardHeader>
                            <CardBody>


                                <Table className="tablesorter" style={{ color: "white" }}>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">Username</th>
                                            <th className="text-center">Password</th>
                                            <th className="text-center">Test Merkezi ID</th>
                                            <th className="text-center">Role </th>
                                            <th className="text-center">Update</th>


                                            <th className="text-center">Delete</th>



                                        </tr>
                                    </thead>
                                    {currentTutorial ? (

                                        <tbody >
                                            <tr style={{ color: "white" }}>
                                                <td>

                                                    <Input
                                                        className={classes.textField}
                                                        type="text"
                                                        name="username"
                                                        value={currentTutorial.username}
                                                        onChange={this.onChangeUsername}
                                                        style={{ backgroundColor: arkaplanrengi, color: "black" }}
                                                    />
                                                </td>

                                                <td>

                                                    <Input
                                                        defaultValue={currentTutorial.enigma}
                                                        className={classes.textField}
                                                        type="text"
                                                        name="enigma"
                                                        onChange={this.onChangeEnigma}
                                                        style={{ backgroundColor: arkaplanrengi, color: "black" }}
                                                    />
                                                </td>

                                                <td>
                                                    <Input
                                                        defaultValue={currentTutorial.tcid}
                                                        className={classes.textField}
                                                        type="text"
                                                        name="tcid"
                                                        onChange={this.onChangeTcid}
                                                        style={{ backgroundColor: arkaplanrengi, color: "black" }}
                                                    />
                                                </td>

                                                <td>
                                                    <Form
                                                        onSubmit={this.handleRegister}
                                                        ref={c => {
                                                            this.form = c;
                                                        }}
                                                    ><RadioGroup
                                                        aria-label="gender"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                        value={currentTutorial.roleId}
                                                        onChange={this.onChangeRD}

                                                    >
                                                            <FormControlLabel value="1" control={<Radio />} label="User" />
                                                            <FormControlLabel value="2" control={<Radio />} label="Zincir" />
                                                        </RadioGroup>

                                                       {/*  {this.state.message && (
                                                            <div className="form-group">
                                                                <div
                                                                    className={
                                                                        this.state.successful
                                                                            ? "alert alert-success"
                                                                            : "alert alert-danger"
                                                                    }
                                                                    role="alert"
                                                                >
                                                                    {this.state.message}
                                                                </div>
                                                            </div>
                                                        )} */}

                                                        <CheckButton
                                                            style={{ display: "none" }}
                                                            ref={c => {
                                                                this.checkBtn = c;
                                                            }}
                                                        />
                                                    </Form>
                                                 
                                                </td>


                                                <td><Button
                                                    type="submit"
                                                    className={classes.update}
                                                    onClick={() => this.updateTutorial()} >
                                                    Update
                                                </Button>
                                                </td>


                                                <td>

                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => this.deleteTutorial(currentTutorial.id)}
                                                    >
                                                        Delete

                                                    </button>
                                                </td>
                                            </tr>


                                        </tbody>
                                    ) : (
                                        <div>
                                            <br />
                                            <p className="text-center">{this.state.message}</p>
                                        </div>
                                    )}
                                </Table>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>


            </div>
        );
    }
}

export default withStyles(styles)(ZincirPaneli)
import React, { Component } from 'react';
//import DatePicker from 'react-datepicker';
import { DatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
import moment from 'moment';
//import { format, compareAsc } from 'date-fns'
import { format } from "date-fns";

import "../assets/scss/black-dashboard-react.scss";
import "../assets/demo/demo.css";
import "../assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from "../routes.js";

import logo from "../assets/img/react-logo.png";
import { BackgroundColorContext } from "../contexts/BackgroundColorContext";

const currentUser = AuthService.getCurrentUser();



export default class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      tcid: false,
      name: "",
      currentUser: { username: "" },
      persons: [],
      startDate: new Date(),
      finishDate: new Date()
    }; 
 
  } 

  getuser() {
    axios.get(`https://mydemo.madilink.net/Airjin/ZincirTumlisteID/${currentUser.tcid}`)

      //ZincirTumliste
      .then(res => {
        const persons = res.data;
        this.setState({ persons });

      })

  }

  sirketsayisi() {

    axios.get(`https://mydemo.madilink.net/Airjin/KullaniciZincirToplam/${currentUser.tcid}`)

      .then(response => this.setState({ Toplam_Merkez: response.data.Toplam_Merkez }));

  }

  componentDidMount() {
 
    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true })
   }
 

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { currentUser, Toplam_Merkez,persons } = this.state;

    return (
      <div className="container">
        {(this.state.userReady) ?
          <div>
            <header className="jumbotron">
              <h3>
                <strong> <h3 style={{ color: "black", fontSize: 31, textAlign: "center" }}>Hoşgeldin <strong>{currentUser.username}</strong></h3></strong>

              </h3>
              <p>
                <strong> <h3 style={{ color: "black", fontSize: 31, textAlign: "center" }}>
                  Test Merkezi ID : <strong>{currentUser.tcid} </strong><br/>
                  Test Merkezi Adı :   <strong> {currentUser.username}</strong></h3></strong>


              </p>


   {/*  {currentUser.roles &&  currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}   */}
            </header>


          </div> : null}

      </div>
    );
  }
}

/*
import React, { Component } from 'react';
//import DatePicker from 'react-datepicker';
import { DatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
import moment from 'moment';
//import { format, compareAsc } from 'date-fns'
import { format } from "date-fns";

import "../assets/scss/black-dashboard-react.scss";
import "../assets/demo/demo.css";
import "../assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from "../routes.js";

import logo from "../assets/img/react-logo.png";
import { BackgroundColorContext } from "../contexts/BackgroundColorContext";

const currentUser = AuthService.getCurrentUser();



export default class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      tcid: false,
      name: "",
      Toplam_Merkez: null,
      currentUser: { username: "" },
      persons: [],
      startDate: new Date(),
      finishDate: new Date()
    };
    /* this.handleChange = this.handleChange.bind(this);
    this.handleChangefinishDate = this.handleChangefinishDate.bind(this);  

  }


  getuser() {
    axios.get(`https://mydemo.madilink.net/Airjin/ZincirTumlisteID/${currentUser.tcid}`)

      //ZincirTumliste
      .then(res => {
        const persons = res.data;
        this.setState({ persons });

      })

  }

  sirketsayisi() {

    axios.get(`https://mydemo.madilink.net/Airjin/KullaniciZincirToplam/${currentUser.tcid}`)

      .then(response => this.setState({ Toplam_Merkez: response.data.Toplam_Merkez }));

  }
  componentDidMount() {
   this.getuser();
    this.sirketsayisi();  

    axios.get(`https://mydemo.madilink.net/Airjin/ZincirTumlisteID/${currentUser.tcid}`)

      //ZincirTumliste
      .then(res => {
        const persons = res.data;
        this.setState({ persons });

      })

    axios.get(`https://mydemo.madilink.net/Airjin/KullaniciZincirToplam/${currentUser.tcid}`)

      .then(response => this.setState({ Toplam_Merkez: response.data.Toplam_Merkez }));



    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true })
  }




  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { currentUser, Toplam_Merkez } = this.state;

    return (
      <div className="container">
        {(this.state.userReady) ?
          <div>
            <header className="jumbotron">
              <h3>
                <strong> <h3 style={{ color: "black", fontSize: 31, textAlign: "center" }}>Hoşgeldin <strong>{currentUser.username},<br />User ID  : </strong>{currentUser.id}</h3></strong>

              </h3>
              <p>
                <strong> <h3 style={{ color: "black", fontSize: 31, textAlign: "center" }}> Test Merkezileri Sayısı  : <strong> {Toplam_Merkez} </strong><br />


                  <Table >
                    {this.state.persons.map(person =>
                      <thead >
                        <tr>
                          <td style={{ color: "black", fontSize: 31, textAlign: "center" }}>{person.name}</td>
                          <td style={{ color: "black", fontSize: 31, textAlign: "center" }}>{person.testcenter_id}</td>
                          <td style={{ color: "black", fontSize: 31, textAlign: "center" }}>{person.place}</td>
                        </tr>
                      </thead>
                    )}


                  </Table>
                </h3></strong>


              </p>



          {currentUser.roles &&
                currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}  

            </header>


          </div> : null}

      </div>
    );
  }
}



*/

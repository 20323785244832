 
import Dashboard from "../src/views/Dashboard.jsx";
import ZincirDashboard from "../src/views/DashboardZincir";
import DashboardKullanici from "../src/views/DashboardKullanici";
import DashboardDegerler from "../src/views/DashboardDegerler"; 
import UserProfile from "../src/views/UserProfile.js"; 
import vertify from "../src/views/vertify.js";


var routes = [ 
  
   {
    path: "/dashboard",
    name: "Admin Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-settings",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/Kullaniciİslemleri",
    name: "Kullanıcı Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: DashboardKullanici,
    layout: "/admin",
  }, 
  {
    path: "/Zincir",
    name: "Zincir Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-molecule-40",
    component: ZincirDashboard,
    layout: "/admin",
  },
  {
    path: "/Sirketsayisi",
    name: "Şirket Sayisi",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: DashboardDegerler,
    layout: "/admin",
  }, 
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/admin",
  },
   
];
export default routes;

import React, { Component } from 'react';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import { DatePicker } from '@y0c/react-datepicker';
import { format } from "date-fns";
import { connect } from "react-redux";
import { createStore, combineReducers } from "redux";
import PropTypes from 'prop-types';
import DashboardControl from './DashboardControl';




// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip, Button
} from "reactstrap"; 
import messages from "../reducers/message";


class Dashboard extends Component {
   static propTypes = {
    messages: PropTypes.array.isRequired
  }  
   

  constructor(props) {
    super(props);

    this.ayrintiliGetir = this.ayrintiliGetir.bind(this);
    this.state = {
      content: "",
      redirect: null,
      userReady: false,
      tcid: false,
      name: "",
      currentUser: { username: "" },
      persons: [],
      birimfiyat: false,
      startDate: new Date(),
      finishDate: new Date(),
      testcenter_id: null,
      companyNames: [],
      data: "",
      totalvalues: [],
      bigChartData: "data1",
      sum_costs: [],
      labels: [],
      message: "",
      control:"yetkisiz giris"
    };
  };



  componentDidUpdate() {
    //this.ayrintiliGetir();
  }

  componentDidMount() {

    this.getuser();
    //this.ayrintiliGetir(); 

    const store = createStore(
      messages,
    //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    console.log(JSON.stringify(store));
  }



  getuser() {

    let client_name = [];
    let totalvalues = [];
    let sum_cost = [];
    let name = [];
    let testcenter_id = [];
    let place = [];




    axios.get(`https://mydemo.madilink.net/Airjin/ZincirListe`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
         for (const dataobj of persons) {

          totalvalues.push(parseInt(dataobj.count_values));
          sum_cost.push(parseInt(dataobj.sum_cost));
          client_name.push((dataobj.client_name));
          name.push((dataobj.name));


        }
        this.setState({ client_name: client_name })
        this.setState({ totalvalues: totalvalues })
        this.setState({ sum_cost: sum_cost })
        this.setState({ name: name })
        this.setState({ testcenter_id: testcenter_id })
        this.setState({ place: place })

      })
      .catch(err => {
        console.log(err)
      }, []);

    /*     <td className="text-center">{person.name}</td>
                        <td className="text-center">{person.testcenter_id}</td>
                        <td className="text-center">{person.place}</td> */

  }

  ayrintiliGetir() {

    let client_name = [];
    let totalvalues = [];
    let sum_cost = [];
    let name = [];
    let testcenter_id = [];
    let place = [];
    let labels = [];



    axios.get(`https://mydemo.madilink.net/Airjin/ZincirListeTarihli/${this.state.startDate}/${this.state.finishDate}`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
         for (const dataobj of persons) {

          totalvalues.push(parseInt(dataobj.count_values));
          sum_cost.push(parseInt(dataobj.sum_cost));
          client_name.push((dataobj.client_name));
          name.push((dataobj.name));

          var date = new Date(dataobj.test_date);
          var formattedDate = format(date, "yyyy/MM/dd");
          labels.push(formattedDate);



        }
        this.setState({ client_name: client_name })
        this.setState({ totalvalues: totalvalues })
        this.setState({ sum_cost: sum_cost })
        this.setState({ name: name })
        this.setState({ testcenter_id: testcenter_id })
        this.setState({ place: place })
        this.setState({ labels: labels })


      })
      .catch(err => {
        console.log(err)
      }, []);

    /*     <td className="text-center">{person.name}</td>
                        <td className="text-center">{person.testcenter_id}</td>
                        <td className="text-center">{person.place}</td> */

  }
  onChange = (date) => {
    var date = new Date(date);
    var formattedDate = format(date, "yyyyMMdd");
     this.setState({ startDate: formattedDate })
 
  }

  onChangeiki = (dateiki) => {
    var date = new Date(dateiki);
    var formattedDate = format(date, "yyyyMMdd");
     this.setState({ finishDate: formattedDate })

     this.ayrintiliGetir();
  }

  render() {
    const { persons, labels, totalvalues, sum_cost } = this.state
    const data1 = {
      labels: labels,
      datasets: [
        {
          label: 'TOTAL VALUES',
          data: totalvalues,
          fill: false, // for Line chart
          backgroundColor: '#FB8833',
          borderColor: '#FB8833' // for Line chart
        },
        {
          label: 'ÜCRET',
          data: sum_cost,
          fill: false, // for Line chart
          backgroundColor: '#17A8F5',
          borderColor: '#17A8F5' // for Line chart
        }
      ]
    };
    const options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
    return (

      <div>
 
        <div className="content">
       
         {/* <DashboardControl messages={this.props.message}>
          
        </DashboardControl> */}
         
         <Row>
            <Col lg="12" md="12">
            </Col>
            <Col lg="12" md="12">

              {persons.map(person =>
                <Card>

                  <CardTitle tag="h4" className="text-center">{person.client_name} 'e/a bağlı şubeler,    </CardTitle>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-center">TEST ŞUBESİ</th>
                          <th className="text-center">TEST CENTER id</th>
                          <th className="text-center">ŞEHİR</th>
                          <th className="text-center"> </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="text-center">{person.name}</td>
                          <td className="text-center">{person.testcenter_id}</td>
                          <td className="text-center">{person.place}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              )}

            </Col>

            <Col lg="12" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="text-center">Total Value</CardTitle>
                </CardHeader>
                <CardBody>
                  {persons.map(person =>

                    <Table className="tablesorter" >

                      <thead className="text-primary">
                        <tr>
                          <th className="text-center">Client Name</th>
                          <th className="text-center">Total Values</th>
                          <th className="text-center">Positive</th>
                          <th className="text-center">Negative</th>
                          <th className="text-center">UnKNOWN</th>
                          <th className="text-center">ÜCRET</th>
                        </tr>
                      </thead>

                      <tbody>

                        <tr>
                          <td className="text-center">{person.name}</td>
                          <td className="text-center">{person.count_values}</td>
                          <td className="text-center">{person.count_positive}</td>
                          <td className="text-center">{person.count_negative}</td>
                          <td className="text-center">{person.count_x}</td>
                          <td className="text-center">{person.sum_cost} €</td>
                        </tr>

                      </tbody>
                    </Table>)}
                </CardBody>
              </Card>

              <Col xs="12">
                <Card >
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h2">Performance</CardTitle>
                      </Col>

                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={data1}
                        options={options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <CardBody>
                <Card >
                  <div>
                    <h3>Search</h3>

                    <h3>Başlangıç Tarihi  </h3>


                    <DatePicker onChange={this.onChange} />


                    <br /> <br />

                    <h3>Bitiş Tarihi   </h3>



                    <DatePicker onChange={this.onChangeiki} />
                    <br />                  <br />

                    <Button onClick={this.ayrintiliGetir}>Search</Button>




                  </div>

                </Card>
              </CardBody>

            </Col>
          </Row>  
       
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { message: state.messages };
};
export default connect(mapStateToProps)(Dashboard);

import axios from "axios"; 
import { VERTIFYCHECK,SET_MESSAGE,CLEAR_VERTIFYCHECK} from "./types";


export function VERTIFY(token){
	return {
		type: VERTIFYCHECK,
		payload: {
			token: token
		}
	}

	
	
}

export function clearMessage() {
	return {
	  type: CLEAR_VERTIFYCHECK,
	}
  }


export function googleauthenticator(token){
	return async dispatch => {
		try{
			const res = await axios.post(`https://mydemo.madilink.net/Airjin/verify`, { token });
			dispatch(VERTIFY(res.data))
			console.console.log(res.data);
		}catch (e) {
		//	dispatch(showError());
	

		}
	}
}



 
import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";
import { ErrorBoundary } from 'react-error-boundary'

import UserLogin from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Admin from "./layouts/Admin/Admin";

import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import { Redirect } from "react-router";
import AL from "../src/components/AdminPanel/Login";
import Vertify from "../src/views/vertify";
import ProtectedRoute from './ProtectedRoute';
import { connect } from "react-redux";
import EventBus from "./common/EventBus";
import { Label } from "reactstrap";

import Dashboard from "../src/views/Dashboard.jsx";
import ZincirDashboard from "../src/views/DashboardZincir";
import DashboardKullanici from "../src/views/DashboardKullanici";
import DashboardDegerler from "../src/views/DashboardDegerler";
import UserProfile from "../src/views/UserProfile.js";
const user = AuthService.getCurrentUser();
/*
 <Route exact path="/" component={Home} />
         <Route exact path="/login" component={UserLogin} />
         <Route exact path="/Airgin" component={AL} />
*/
const routes = [
  {
    path: "/home",
    component: Home
  },
  {
    path: "/login",
    component: UserLogin
  },
  {
    path: "/user",
    component: BoardUser
  },
  {
    path: "/zincir",
    component: BoardModerator
  },
  {
    path: "/Airgin",
    component: AL
  },
  {
    path: "/Vertify",
    component: Vertify
  },
    {
    path: "/admin/dashboard",
    name: "Admin Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-settings",
    component: BoardAdmin,
  },  
  {
    path: "/admin/Kullaniciİslemleri",
    name: "Kullanıcı Dashboard",
    rtlName: "لوحة القيادة",
    component: DashboardKullanici,
  },
  {
    path: "/admin/Zincir",
    name: "Zincir Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-molecule-40",
    component: ZincirDashboard,
  },
  {
    path: "/admin/Sirketsayisi",
    name: "Şirket Sayisi",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: DashboardDegerler,
  },
  {
    path: "/admin/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
  },
 

];

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}



class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showUserBoard: false,
      currentUser: undefined,
      isAuthenticatedUser: false
    };
  }

  componentDidMount() {
 //   console.log(user)

    if (user) {

      this.setState({
        currentUser: user,
        showUserBoard: user.roles.includes("ROLE_USER"),
        showModeratorBoard: user.roles.includes("ROLE_ZINCIR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        isAuthenticatedUser: true
      });

    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showUserBoard: false,
      currentUser: undefined,
      isAuthenticatedUser: false
    });
  }



  render() {
    const { currentUser, showModeratorBoard, showUserBoard, showAdminBoard, isAuthenticatedUser } = this.state;

    function Child() {


      return (
        <div>
          <h3>ID: </h3>
        </div>
      );
    }



    const SelectAuthenticated = () => {


      if (showAdminBoard) {
        return <Switch>
          {
            routes.map((route, i) => (

              <RouteWithSubRoutes key={i} {...route} />

            ))

          }

        </Switch>
      }
      if (showUserBoard) {
        return <Switch>
          {
            routes.map((route, i) => (

              <RouteWithSubRoutes key={i} {...route} />

            ))

          }

        </Switch>
      }
      if (showModeratorBoard) {
        return <Switch>
          {
            routes.map((route, i) => (

              <RouteWithSubRoutes key={i} {...route} />

            ))

          }

        </Switch>
      }
      else {
        return <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={UserLogin} />
          <Route exact path="/Airgin" component={AL} />
        {/*  <Route path="/admin/dashboard" component={BoardAdmin}/> */}
           {/*   {showAdminBoard ? <Redirect to="/admin/dashboard" /> : <Home />}
          </Route>
          */}

        </Switch>
      }



    }

    return (
      <div>
        <nav className="navbar-expand navbar navbar-dark bg-primary  ">

          <h1 className="navbar-nav" style={{ paddingTop: "12" }}>CoV-Test Manager </h1><Label style={{ fontStyle: "italic", color: "whitesmoke" }}> 1.0v</Label>

          <div style={{ marginTop: 15, marginRight: 1, textAlign: "center" }}>
            {showModeratorBoard && (
              <Redirect to="/zincir/dashboard" />
            )}
          </div>


          <div style={{ marginTop: 15, marginRight: 1, textAlign: "center" }}>
            {showUserBoard && (
              <Redirect to="/user" />
            )}
          </div>
          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>

          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  User Login
                </Link>
              </li>

            </div>
          )}

        </nav>

        <div className="container">

          <SelectAuthenticated />

        </div>

      </div>
    );

  }

}

const mapStateToProps = (state, props) => {
  //  console.log(props)
  return {
    ...state,
  }

};

export default connect(mapStateToProps)(App);
